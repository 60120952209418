import React from 'react'
import { Link } from 'gatsby'
import Translate from '../utils/translate'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'

export default (props) => {
  const codeLanguage = 'en'
  const languages = require('../data/languages');

  const data = useStaticQuery(graphql`
    query {
        img404: file(relativePath: { eq: "404.jpg" }) {
          childImageSharp {
            fluid(maxHeight: 758) {
              ...GatsbyImageSharpFluid
            }
          }
        }
    }
  `)

  return (
    <div className='max-w-full'>
      <div className='flex items-center flex-shrink-0 text-white ml-6 mt-6'>
        <Link to={codeLanguage === languages.defaultLangKey ? '/' : '/es'} className='flex'>
          <p className='text-4xl font-bold text-black'>&lt;/</p>
          <p className='text-4xl font-bold text-blue-500'>&gt;</p>
        </Link>
      </div>

      <div className='flex justify-center items-center w-screen' style={{height: '65vh'}}>
        <div className='block'>
          <div className='flex justify-center'>
            <h1 className='font-bold text-gray-700 text-404'>4</h1>
            <div className='w24 h-24 mt-8' style={{minHeight: '6rem', minWidth: '6rem'}}>
              <Img fluid={data.img404.childImageSharp.fluid} className='border rounded-full border-solid border-gray-600 ' alt='404' />
            </div>
            <h1 className='font-bold text-gray-700 text-404'>4</h1>
          </div>

          <div className='block text-center'>
            <h2 className='font-light text-gray-700 mb-8 mx-4'>{Translate(codeLanguage, 'page_not_found')}</h2>
            <Link to={codeLanguage === languages.defaultLangKey ? '/' : '/es'} className="bg-blue-500 hover:bg-blue-700 text-white font-normal text-base py-2 px-12 rounded">
              {Translate(codeLanguage, 'back_home')}
            </Link>
          </div>
        </div>
      </div>
      
    </div>
  )
}
